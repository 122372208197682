<!--
 * @Description: 周视图
 * @Author: luocheng
 * @Date: 2021-07-20 15:56:33
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-05-09 14:53:37
-->
<template>
  <div class="week-view">
    <!-- 头部 -->
    <header class="header">
      <h3 class="month">{{ month | getChinaMonth }}</h3>
      <ul class="week-days">
        <li v-for="(item, index) in weekList" :key="index">
          <span :class="{
            'label': index === 5 || index === 6
          }">{{ item.date }} </span>
          <span class="label">{{ item.label }} </span>
        </li>
      </ul>
    </header>
    <article class="all-day">
      <p class="label">全天</p>
      <div class="all-content">
        <section class="day" v-for="(item, index) in weekList" :key="index">
          <div class="item" v-for="(dayItem,index) in item.allDayList" @click="handleItemClick(dayItem)" :key="dayItem.msg_type + 'msgType' + index"
          :class="[2, 5].includes(item.status) ? 'done' : 'unstart'"
          >
            {{ dayItem.title }}
          </div>
        </section>
        <!-- <div class="scroll-bar" v-if="weekList.some(el => el.allDayList.length > 4)"></div> -->
      </div>
    </article>
    <article class="content" ref="boxMain">
      <div class="time-box">
        <li class="hour" v-for="item in timeList" :key="item.time" :id="item.time"
          :class="{
            'is-target': item.isTarget,
            'on-hour': item.time.indexOf('00') !== -1
          }"
          :style="{
            top: item.isTarget ? (-1 * (60 - item.minutes) / 60) * 56 + 'px': 0
          }"
        >
          <p class="time" :style="{visibility: item.show ? '' : 'hidden'}">{{ item.time }}</p>
          <div class="area"></div>
        </li>
      </div>
      <article class="week-box">
        <section class="week-item" v-for="(item, index) in weekList" :key="index"
          :class="{
            'weekend': index === 5 || index === 6
          }"
        >
          <DayView :isFirstDay="index === 0" :isWeek="true" :todoList="item" :key="index"></DayView>
        </section>
      </article>
    </article>
  </div>
</template>

<script>
import DayView from '../dayView/Index';

export default {
  name: 'WeekView',
  components: {
    DayView
  },
  props: {
    // 当前所选周(周一的时间戳)
    timestamp: {
      type: Number,
      default: 0,
      required: true
    }
  },
  data() {
    return {
      // 本周周一为几月
      month: '',
      // 周列表
      weekList: [
        {
          label: '周一',
          date: '',
          data: [],
          allDayList:[]
        },
        {
          label: '周二',
          date: '',
          data: [],
          allDayList: []
        },
        {
          label: '周三',
          date: '',
          data: [],
          allDayList: []
        },
        {
          label: '周四',
          date: '',
          data: [],
          allDayList:[]
        },
        {
          label: '周五',
          date: '',
          data: [],
          allDayList:[]
        },
        {
          label: '周六',
          date: '',
          data: [],
          allDayList:[]
        },
        {
          label: '周日',
          date: '',
          data: [],
          allDayList:[]
        }
      ],
      // 全天
      // // 全天日程列表(测试数据)
      // allDayList: [
      //   {
      //     id: 1,
      //     status: 1, // 1 已完成 2 未完成
      //     title: '已完成1'
      //   },
      //   {
      //     id: 2,
      //     status: 2,
      //     title: '未完成1'
      //   },
      //   {
      //     id: 4,
      //     status: 1, // 1 已完成 2 未完成
      //     title: '已完成2'
      //   },
      //   {
      //     id: 5,
      //     status: 2,
      //     title: '未完成1'
      //   },
      //   {
      //     id: 6,
      //     status: 2,
      //     title: '未完成1'
      //   },
      // ],
      // 时刻表
      timeList: [],
      HOURHEIGHT: 57
    }
  },
  created() {
    this.initWeek();
    this.initTable();
  },
  mounted() {
    this.$nextTick(() => {
      // 第一视角跳转八点
      if (this.$refs.boxMain) {
        this.$refs.boxMain.scrollTop = 8 * this.HOURHEIGHT;
      }
    });
  },
  computed: {
    taskList() {
      return this.$store.getters.getActiveItem
    }
  },
  watch: {
    timestamp() {
      this.initWeek()
    },
    taskList: {
      handler(n) {
        if (n) {
          this.$nextTick(() => {
            this.initData()
          })
        }
      },
      immediate: true
    }
  },
  filters: {
    // 月份中文
    getChinaMonth(val) {
      if (!val || isNaN(+val)) return '';
      const MONTHS = ['一', '二', '三', '四', '五', '六','七', '八', '九', '十', '十一', '十二'];
      if (val - 1 > MONTHS.length) return '';
      return `${ MONTHS[val -1] }月`;
    }
  },
  methods: {
    // initData
    initData() {
      const list = JSON.parse(JSON.stringify(this.weekList))
      list.map(el => {
        el.data= []
        el.allDayList = []
      })
      this.taskList.data.map(el => {
        const ts = Number(el.endDate.substring(8, 10))
        const index = list.findIndex(item => item.date == ts)
        if (index !== -1) {
          list[index].data.push(el)
          if(el.allDay === 1) {
            list[index].allDayList.push(el)
          }
        }
      })
      this.weekList = JSON.parse(JSON.stringify(list))
    },
    /**
     * @desc: 根据所选的周初始化表格数据
     * @param {*}
     * @return {*}
     */
    initWeek() {
      let time = this.getMonday(this.timestamp)
      let monday = this.getDateByTime(time);
      this.month = this.getSign(time).month;
      if (!monday) return;
      for (let i = 0; i < 7; i++) {
        this.weekList.splice(i, 1, {
          ...this.weekList[i],
          date: this.getDateByTime(time)
        });
        time += 1000 * 60 * 60 *24 // 日期加一
      }
    },
    /**
     * @desc: 初始化table 时间刻度 当前时间
     * @param {*}
     * @return {*}
     */
     initTable() {
      this.timeList = [];
      const now = new Date();
      const hour = now.getHours();
      const minutes = now.getMinutes();
      let targetIndex = 0
      for(let i = 0; i <= 24; i++){
        this.timeList.push({
          time: i < 10 ? `0${i}:00` : `${i}:00`,
          isTarget: hour === i && minutes === 0,
          hour: i,
          minutes: 0,
          show: true
        });
        if (hour === i && minutes !== 0) {
          this.timeList.push({
            time: `${hour < 10 ? '0' + hour : hour}:${minutes < 10 ? '0' + minutes : minutes}`,
            isTarget: true,
            hour: i,
            minutes,
            show: true
          });targetIndex = i
        }
      }
      if(minutes < 20) {
        this.timeList[targetIndex].show = false
      } else if(minutes > 30) {
        this.timeList[targetIndex + 2].show = false
      }
    },
    /**
     * @desc: 根据一周中的某一天返回当周一 00:00:00 时间戳
     * @param {*} times 某一周的某一天时间戳
     * @return {*} 当周一 00:00:00 时间戳
     */
     getMonday(times) {
      if (!times || isNaN(times)) return;
      const date = new Date(times);
      const weekDay = date.getDay() === 0 ? 7 : date.getDay(); // 周几
      // 非周一时候日期需要根据周几往前推周几 - 1 天
      // 月份第一天或不够减需要将月份向前推一个月
      // 如果月份为1月的时候年也需要往前倒推一年
      const lessCount = weekDay - 1;
      const { year, month, day } = this.getSign(times - lessCount * 86400000)
      return new Date(`${year}/${month}/${day} 00:00:00`).getTime();
    },
    /**
     * @desc: 根据时间戳返回相关日期对象
     * @param {*} times
     * @return {*}
     */
     getSign(times, needSort = false) {
      if (!times || isNaN(times)) return {};
      const dateObj = new Date(times);
      const year = dateObj.getFullYear();
      const month = dateObj.getMonth() + 1;
      const day = dateObj.getDate();
      const weekDay = dateObj.getDay();
      const hours = dateObj.getHours();
      const minutes = dateObj.getMinutes();
      const seconds = dateObj.getSeconds();
      return {
        year,
        month,
        day,
        weekDay,
        hours,
        weekSort: needSort ? this.getWeekSort(times) : '',
        minutes,
        seconds,
        // 零点的时间
        timestampZero: new Date(`${year}/${month}/${day} 00:00:00`).getTime()
      };
    },
    /**
     * @desc: 根据时间戳返回日期 12
     * @param {*}
     * @return {*}
     */
    getDateByTime(time) {
      if (!time || isNaN(time)) return '';
      return  new Date(time).getDate();
    },
    // 传递活动小项
    handleItemClick(item) {
      // 4.0 - 兼容处理：发送消息到引用页面
      //if(this.$checkIfVersionFour()){
        let data = {
          event:'showDetailModal',
          form_uuid:item.form_id,
          flow_id:item.flow_id,
          data_id:item.id,
          module_name:item.module_name,
          object_name:item.object_name,
        }
        window.parent.postMessage(data,'*')
        return
     // }
     // this.$store.commit('setActivetaskData', item)
    }
  }
}
</script>

<style lang="less" scoped>
@labelWidth: 54px;
@areaHeight: 57px;
@tagetColor: #cc4429;
.week-view{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .header{
    display: flex;
    height: 30px;
    border-bottom: 1px solid #d7dae0;
    .month{
      width: @labelWidth;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: center;
      color: #202126;
      line-height: 30px;
    }
    .week-days{
      flex: 1;
      display: flex;
      list-style: none;
      li{
        flex: 1;
        display: flex;
        span{
          flex: 1;
          line-height: 30px;
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #202126;
          line-height: 30px;
          text-align: right;
          &.label{
            color: #8a8f99;
          }
          &:last-of-type{
            text-align: left;
            margin-left: 6px;
          }
        }
      }
    }
  }
  .all-day{
    box-sizing: border-box;
    width: 100%;
    display: flex;
    border-top: 1px solid #d9dce1;
    border-bottom: 3px solid #d9dce1;
    padding: 1px 0;
    // padding-left: 53px;
    .label{
      width: 54px;
      margin-top: 1px;
      line-height: 20px;
      font-size: 12px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: center;
      color: #7c808a;
      line-height: 20px;
    }
    .all-content{
      display: flex;
      flex: 1;
      max-height: 92px;
      overflow-x: hidden;
      overflow-y: auto;
      .day{
        flex: 1;
        width: 14.28%;
        box-sizing: border-box;
        border-right: 1px solid #d7dae0;
        padding: 0px 1px;
        .item{
          box-sizing: border-box;
          max-width: 100%;
          height: 20px;
          padding: 0 12px;
          margin: 1px 0;
          text-align: left;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 12px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 500;
          text-align: left;
          line-height: 20px;
          border-radius: 4px;
          cursor: pointer;
          &.done{
            background: rgba(215,218,224,0.60);
            color: #8a8f99;
          }
          &.unstart{
            background: rgba(71,157,255,0.20);
            color: var(--themeColor);
          }
          &:hover{
            color: #fff;
            &.done{
              background: #bdc0c7;
            }
            &.unstart{
              background: #479dff;
            }
          }
        }
      }
    }
    .scroll-bar{
      height: 100%;
      width: 15px;
      box-sizing: border-box;
      background: rgba(248, 249, 248, 1);
      border-left: 1px solid rgba(232, 232, 232, 1);
      border-right: 1px solid rgba(232, 232, 232, 1);
    }
  }
  .content{
    flex: 1;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    // padding-left: @labelWidth;
    padding-top: 10px;
    display: flex;
    overflow: hidden;
    overflow-y: auto;
    height: 1368px;
    .time-box{
      flex: 1;
      width: 100%;
      list-style: none;
      position: relative;
      background: transparent;
      z-index: 100;
      .hour{
        width: 100%;
        display: flex;
        height: @areaHeight;
        &:last-of-type{
          height: 0;
          .area{
            height: 0;
          }
        }
        &.is-target{
          height: 0;
          position: relative;
          &.on-hour{
            height: @areaHeight;
            top: 0;
            position: relative;
            .area{
              height: @areaHeight;
            }
          }
          .time{
            color: @tagetColor;
          }
          .area{
            height: 0;
            border-top: 1px solid @tagetColor;
            &:before{
              content: '';
              display: block;
              height: 10px;
              width: 10px;
              position: absolute;
              top: -5px;
              left: - 5px;
              border-radius: 5px;
              background: @tagetColor;
            }
          }
        }
        .time{
          width: @labelWidth;
          font-size: 12px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: right;
          color: #7c808a;
          line-height: 12px;
          box-sizing: border-box;
          padding-right: 12px;
          text-align: right;
          margin-top: -6px;
          position: relative;
          z-index: 9999;
        }
        .area{
          flex: 1;
          height: @areaHeight;
          box-sizing: border-box;
          border-top: 1px solid #d7dae0;
          background: transparent;
        }
      }
    }
    .week-box{
      box-sizing: border-box;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      padding-left: @labelWidth;
      background: transparent;
      display: flex;
      // z-index: 800;
      .week-item{
        flex: 1;
        border-right: 1px solid #d7dae0;
        height: 1378px;
        &.weekend{
          background: #f8f8f9;
        }
        &:first-child{
          border-left: 1px solid #d7dae0;
        }
      }
    }
  }
}
</style>