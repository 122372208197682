<!--
 * @Description: 中间区域头部赛选项目
 * @Author: luocheng
 * @Date: 2021-07-16 15:40:37
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-05-09 14:53:29
-->
<template>
  <div class="main-header">
    <!-- 顶部统计及类型相关 -->
    <div class="top">
      <ul class="tabs">
        <li class="tab"
          v-for="tab in tabList" :key="tab.value"
          @click="onTab(tab)" :class="{
          'active': currentTab === tab.value
          }">
          {{ tab.label }}
        </li>
      </ul>
      <div class="statis" v-if="currentTab !== 'list' && !loadingFlag">
        <template v-for="(data, index) in dataList">
          <section class="statis-item" v-if="data.count || index == 0" @click="onData(data)"
             :key="data.value"
            :class="{
              'curent': currentData === data.value
            }"
          >
            <span class="label">{{ data.label }}</span>
            <span class="line"> | </span>
            <span class="count">{{ data.count }}</span>
          </section>
        </template>
        
      </div>
      <div class="statis" v-else>
        <template  v-for="(data, index) in dataList1">
          <section v-if="(data.count !== 0 || index == 0) && !loadingFlag " class="statis-item" @click="setData(data)"
            :key="data.value"
            :class="{
              'curent': currentLabel === data.label
            }"
          >
            <span><i class="iconfont" :class="data.icon"></i>&nbsp;</span>
            <span class="label">{{ data.label }}</span>
            <span class="line"> | </span>
            <span class="count">{{ data.count }}</span>
          </section>
        </template>
      </div>
      <div class="control">
        <!-- 筛选 -->
        <el-popover
          ref="searchPop"
          placement="bottom-end"
        >
          <div>
            <div class="screen-div">
              <div>
                <el-form>
                  <p class="form-item-label">责任人</p>
                  <el-form-item>
                    <!-- <SelectUser isCheckType="selectCheckbox" :transfer="false"  @on-choose="getSelectUser"></SelectUser> -->
                    <el-select v-model="params.user_name" filterable clearable>
                      <template v-for="(item, index) in userList">
                        <el-option :key="index" :value="item.name">{{ item.name }}</el-option>
                      </template>
                    </el-select>
                    <!-- <Input v-model="params.user_name" placeholder="请输入..." /> -->
                  </el-form-item>
                  <p class="form-item-label">部门</p>
                  <el-form-item>
                    <el-select v-model="params.section_id" clearable filterable>
                      <template v-for="(item, index) in sectionsList">
                        <el-option :key="index" :value="item.id">{{ item.name }}</el-option>
                      </template>
                    </el-select>
                  </el-form-item>
                  <!-- <p class="form-item-label">所属项目</p>
                  <el-form-item>
                    <Input />
                  </el-form-item> -->
                  <p class="form-item-label">开始时间</p>
                  <el-form-item>
                    <el-date-picker style="width: 100%" type="datetime"  v-model="params.start_time" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" placeholder="请选择"></el-date-picker>
                  </el-form-item>
                  <p class="form-item-label">结束时间</p>
                  <el-form-item>
                    <el-date-picker style="width: 100%" type="datetime"  v-model="params.end_time" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" placeholder="请选择"></el-date-picker>
                  </el-form-item>
                </el-form>
              </div>
              <div class="action-btn">
                <el-button type="primary" style="margin-right: 10px"  @click="handleSearch()">筛 选</el-button>
                <el-button @click="handleReSet()"><i class="iconfont iconxiaoque active"></i> 清 空</el-button>
              </div>
            </div>
          </div>
          <i slot="reference"  class="iconfont iconjijia_guolv active cursor-pointer" :style="currentTab === 'list' ? 'margin-right: 16px' : ''"></i>
        </el-popover>
        <!-- 排序 -->
        <el-popover
          v-if="currentTab === 'list'"
          placement="bottom-end"
        >
          <div>
            <template>
                <el-radio-group v-model="sortFiled" @change="sortFiledChange" >
                  <div style="height:8px"></div>
                  <el-radio label="">
                        <span>默认</span>
                    </el-radio>
                  <div style="height:8px"></div>
                  <el-radio label="priority">
                        <span>优先级</span>
                    </el-radio>
                    <div style="height:8px"></div>
                    <el-radio label="endDate">
                        <span>截止日期</span>
                    </el-radio>
                    <div style="height:8px"></div>
                    <el-radio label="created_at">
                        <span>创建日期</span>
                    </el-radio>
                </el-radio-group>
            </template>
          </div>
          <i slot="reference"  class="iconfont iconpaixu active cursor-pointer"></i>
        </el-popover>
      </div>
    </div>
    <!-- 切换 日 周 月 -->
    <SelfDatePicker
      v-if="currentTab !== 'list'"
      v-model="currentDate"
      :type="currentTab"
    ></SelfDatePicker>
  </div>
</template>

<script>
import {Popover,Form,FormItem,Select,Option,DatePicker,Button,Radio,RadioGroup} from 'element-ui'
import SelfDatePicker from '@/components/todoList/datePicker/Index';
import { getSections, getUsers } from '@/apis/objectivesRespon' // 获取机构

export default {
  name: 'Header',
  components: {
    SelfDatePicker,
    'el-popover':Popover,
    'el-form':Form,
    'el-form-item':FormItem,
    'el-select':Select,
    'el-option':Option,
    'el-date-picker':DatePicker,
    'el-button':Button,
    'el-radio':Radio,
    'el-radio-group':RadioGroup
    // SelectUser
  },
  data() {
    return {
      // tab
      tabList: [
        {
          value: 'list',
          label: '列表'
        },
        {
          value: 'date',
          label: '日'
        },
        {
          value: 'week',
          label: '周'
        },
        {
          value: 'month',
          label: '月'
        }
      ],
      currentTab: 'list',
      // 统计
      currentData: 'all',
      // 当前所选 日/月/周时间戳
      currentDate: '',
      sortFiled: '', // 排序字段
      params: {
        user_name: '', // 责任人
        section_id: '', // 部门id
        start_time: '', //开始时间
        end_time: '', //截止时间
      }, //筛选条件
      sectionsList: [],
      userList: [],
      currentLabel: '全部'
    }
  },
  computed: {
    loadingFlag() {
      return this.$store.getters.getSearchLoding
    },
    dataList1() {
      const data = this.$store.getters.getIndexMenuList
      const dataList = [
        {
          label: '全部',
          moudulName: '',
          count: 0,
          icon: 'iconjijia_dangqian',
          data: []
        },
        {
          label: '安全管理',
          moudulName: '',
          icon: 'iconanquanhuanbao',
          count: 0,
          data: []
        },
        {
          label: '日志',
          value: 'done',
          icon: 'iconribao',
          moudulName: '',
          count: 0,
          data: []
        },
        {
          label: '质量巡检',
          value: 'done',
          icon: 'iconanquanhuanbao',
          moudulName: 'iconzhiliangguanli1',
          count: 0,
          data: []
        },
        {
          label: '任务',
          value: 'done',
          icon: 'iconrenwu',
          moudulName: 'assignment',
          count: 0,
          data: []
        }
      ]
      data[0].data.map(el => {
        dataList[0].data.push(el)
        const res = dataList.find(item => el.module_name == item.moudulName)
        if(res) {
          res.data.push(el)
        }
      })
      dataList.map(el => {
        el.count = el.data.length
      })
      return dataList
    },
    dataList() {
      const data = this.$store.getters.getIndexMenuList
      const dataList= [
        {
          label: '全部',
          value: 'all',
          count: 0,
          data: []
        },
        {
          label: '未完成',
          value: 'un',
          status: '',
          count: 0,
          data: []
        },
        {
          label: '已完成',
          value: 'done',
          count: 0,
          data: []
        }
      ]
      data[0].data.map(el => {
        dataList[0].data.push(el)
        if([2, 5].includes(el.status)) {
          dataList[2].data.push(el)
        } else {
          dataList[1].data.push(el)
        }
      })
      dataList.map(el => {
        el.count = el.data.length
      })
      return dataList
    }
  },
  created() {
    this.getSections()
  },
  watch: {
    // currentDate
    currentDate(newv) {
      // console.log(newv, '---获取新值');
      this.$emit('dateChange', newv);
    }
  },
  filters: {
    // 将时间搓转换为日期
    // filterDay(time) {
    //   if (!time  || isNaN(time)) return '';
    //   const date = new Date(time);
    //   const year = date.getFullYear();
    //   const addZero = (val) => {
    //   if (isNaN(val) || val < 0 || val >= 10) return val;
    //     return `0${val}`;
    //   }
    //   const month = addZero(date.getMonth() + 1);
    //   const day = addZero(date.getDate());
    //   return `${year}-${month}-${day}`
    // }
  },
  mounted() {
    const paramsStore = this.$store.getters.getSearchParams
    for (const key in paramsStore) {
      if (paramsStore[key]) {
        this.params[key] = paramsStore[key]
      }
    }
  },
  methods: {
    getSections() {
      getSections().then(res => {
        if (res.data.code === 200) {
          this.sectionsList = res.data.data
        }
      })
      getUsers().then(res => {
        if(res.data.code === 200) {
          this.userList = res.data.data
        }
      })
    },
    // 筛选
    handleSearch() {
      console.log('筛选')
      // 只有两层，所以选用父级传递,为了方便后面使用veux存储
      this.$store.commit("setSearchParams", this.params);
      this.$emit('searchParamsChange', {data: this.params, type: 'searchParmas'})
      this.$refs.searchPop.visible = false
    },
    // 重置
    handleReSet() {
      console.log('重置')
      for (const key in this.params) {
        if(Array.isArray(this.params[key])) {
          this.params[key] = []
        } else {
          this.params[key] = ''
        }
      }
      this.$store.commit("setSearchParams", this.params);
      this.$emit('searchParamsChange', {data: this.params, type: 'searchParmas'})
    },
    // 排序字段改变
    sortFiledChange(res) {
      // 向外传递排序字段
      this.$store.commit("setSortFiled", res);
      this.$emit('searchParamsChange', {data: res, type: 'sortFiled'})
    },
    /**
     * @desc: 重新获取数据
     * @param {*}
     * @return {*}
     */
    getTodoList() {},
    /**
     * @desc: 点击tab
     * @param {*} tab
     * @return {*}
     */
    onTab(tab) {
      this.currentTab = tab.value;
      for (const key in this.params) {
        if(Array.isArray(this.params[key])) {
          this.params[key] = []
        } else {
          this.params[key] = ''
        }
      }
      this.sortFiled = ''
      this.$store.commit("setSearchParams", {});
      this.$store.commit("setSortFiled", '');
      this.$emit('getType', this.currentTab);
    },
    /**
     * @desc: 点击统计tab
     * @param {*} data
     * @return {*}
     */
    onData(data) {
      this.currentData = data.value
      console.log(new Date().setTime(this.currentData))
      this.$store.commit('setActiveItem', data)
      this.$store.commit('setActiveItemIndex', 0)
    },
    setData(data) {
      this.currentLabel = data.label
      this.$store.commit('setActiveItem', data)
      this.$store.commit('setActiveItemIndex', 0)
    }
  }
}
</script>

<style lang="less" scoped>
.main-header{
  width: 100%;
  .top{
    display: flex;
    height: 22px;
    .tabs{
      border-radius: 4px;
      display: flex;
      list-style: none;
      box-sizing: border-box;
      margin-right: 20px;
      .tab{
        width: 64px;
        height: 22px;
        text-align: center;
        line-height: 22px;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #202126;
        box-sizing: border-box;
        border: 1px solid #eaebed;
        border-right: 1px solid transparent;
        cursor: pointer;
        &:hover{
          background: rgba(17, 111, 217, 0.05);
        }
        &:nth-of-type(1) {
          border-left: 1px solid #eaebed;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
        &:last-of-type {
          border-right: 1px solid #eaebed;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
        &.active{
          border: 1px solid var(--themeColor);
          background: rgba(17,111,217,0.05);
          box-sizing: border-box;
          border-radius: 0;
          box-shadow: 1px 0px 1px #f2f3f5;
        }
      }
    }
    .statis{
      flex: 1;
      display: flex;
      .statis-item{
        display: flex;
        box-sizing: border-box;
        padding: 4px 12px;
        height: 22px;
        cursor: pointer;
        &:hover{
          span{
            color: #202126;
          }
        }
        span{
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #8a8f99;
          line-height: 14px;
          &.line{
            padding: 0 8px;
            font-size: 12px;
          }
        }
        &.curent{
          span{
            color: #202126;
          }
        }
      }
    }
    .control{
      color:var(--themeColor);
      .screen-div{
        width: 300px;
        padding: 10px;
        .form-item-label{
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #202126;
          line-height: 22px;
        }
        .action-btn{
          border-top: 1px solid #f5f5f5;
          padding: 10px 0;
        }
      }
    }
  }
}
</style>