<!--
 * @Description: 月视图
 * @Author: luocheng
 * @Date: 2021-07-21 09:15:15
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-05-09 14:53:33
-->
<template>
  <div class="month-view" ref="monthView">
    <header class="weekday">
      <p v-for="(weekDay, index) in weekData" :key="index"
        :class="{ 'weekend': index === 5 || index === 6 }"
      >{{ weekDay }}</p>
    </header>
    <article class="month-main" ref="monthMain">
      <section class="date-item"
        :ref="'dateItem' + index"
        v-for="(item, index) in dateList"
        :key="item.date">
        <div class="date-label">
          <label :class="{
            'special': item.disabled,
            'is-today': item.isToday
          }">{{ item.label }}</label>
        </div>
        <!-- 待办列表 -->
        <template v-if="item.todoList && item.todoList.length">
          <ul class="todo-list">
            <!-- eslint-disable -->
            <li class="todo ellipsis" v-for="(todo, todoIndex) in item.todoList" :key="todoIndex"
              :ref="'todo' + index + '-' + todoIndex"
              v-if="(item.todoList.length > 5 && todoIndex < 4) || item.todoList.length <= 5"
              @click="handleItemClick(todo)"
              :class="{
                'is-full-complate': todo.allDay && ![2, 5].includes(todo.status), //todo.status === 1, // 全天完成
                'is-full-unfinish': todo.allDay && [2, 5].includes(todo.status), //todo.status !== 1, // 全天未完成
                'is-foucus': focusId === todo.id, // 当前聚焦
                'full-day': todo.allDay, // 全天
                'is-span': todo.isSpan, // 跨天日常
                'is-start-full': todo.isSpanStart, // 全天 跨天 开始那天
                'is-end-full': todo.isSpanEnd, // 全天 跨天 结束那天
                'not-full-day': !todo.allDay, // 非全天
                'target-create': quietCreate && quietCreate.date === item.date && quietCreate.spaceTodoIndex === todoIndex // 创建
              }"
              @mouseover="onMouseover(todo)"
              @mouseout="onMouseout"
            >
              <!-- 全天或空白不显示 -->
              <label class="point" v-if="!todo.allDay && !todo.isSpace && todo.msg_type !== 3"
                :class="{
                  'unfinish': [2, 5].includes(todo.status), //todo.status === 2
                }"
              ></label>
              <!-- 跨天的全天启程 当为第一条或最后一条或星期1时才显示
                空白日程且不为当前新增不显示
              -->
              <p class="title ellipsis"
                v-if="(!todo.allDay
                  || !todo.isSpan
                  || (todo.isSpanStart || todo.isMonday || (item.day === 1 && index === 0)))
                  && (
                    !todo.isSpace
                    ||
                    (quietCreate && quietCreate.date === item.date && quietCreate.spaceTodoIndex === todoIndex)
                  )"
                :class="{
                  'not-un-finished': !todo.allDay && [2, 5].includes(todo.status), //todo.status === 2
                }"
              >
                {{ todo.title || '无主题' }}
              </p>
            </li>
          </ul>
          <p class="overflow ellipsis" v-if="item.todoList.length > 5" @click="onShowHide(index)">还有{{ item.todoList.length - 4 }}项</p>
          <!-- 完整数据列表 -->
          <section class="all-todo" v-if="item.todoList.length > 5 && showAll && showMoreIndex === index">
            <h3 class="title">{{ item.label }}</h3>
            <ul class="list">
              <li class="todo ellipsis" v-for="(todo, todoIndex) in item.todoList" :key="todoIndex"
                @click="handleItemClick(todo)"
                :ref="'todo' + index + '-full-' + todoIndex"
                :class="{
                  'is-full-complate': todo.allDay && ![2, 5].includes(todo.status), //todo.status === 1, // 全天完成
                  'is-full-unfinish': todo.allDay && [2, 5].includes(todo.status), //todo.status !== 1, // 全天未完成
                  'is-foucus': focusId === todo.id, // 当前聚焦
                  'full-day': todo.allDay, // 全天
                  'is-span': todo.isSpan, // 跨天日常
                  'is-start-full': todo.isSpanStart, // 全天 跨天 开始那天
                  'is-end-full': todo.isSpanEnd, // 全天 跨天 结束那天
                  'not-full-day': !todo.allDay, // 非全天
                  'target-create': quietCreate && quietCreate.date === item.date && quietCreate.spaceTodoIndex === todoIndex // 创建
                }"
              >
                <!-- 全天或空白不显示 -->
                <label class="point" v-if="!todo.isSpace && todo.msg_type !== 3"
                  :class="{
                    'unfinish': [2, 5].includes(todo.status), //todo.status === 2
                  }"
                ></label>
                <p class="child-title ellipsis">
                  {{ todo.title || '无主题' }}
                </p>
              </li>
            </ul>
          </section>
        </template>
      </section>
    </article>
    <!-- 弹出层 -->
    <Popup v-if="showModel"
      :positon="popupPosition"
      :infoData="detailsObj"
    ></Popup>
    <div class="mask-box" v-if="showModel || showAll" @click="onMask"></div>
  </div>
</template>

<script>
import Popup from '@/components/todoList/common/Popup';

export default {
  name: 'MonthView',
  components: {
    Popup
  },
  props: {
    // 月中某一天时间戳
    timestamp: {
      type: Number,
      default: 0,
      required: true
    }
  },
  data() {
    return {
      // 日期列表，
      dateList: [],
      // 大月 31天
      largeMonths: [1, 3, 5, 7, 8, 10, 12],
      smallMonths: [4, 6, 9, 11],
      // 当前日期
      todayDate: '',
      // 周日期
      weekData: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
      // 本月待办事项列表(测试数据)
      // todos: [
      //   {
      //     id: 1,
      //     startDate: '2021/7/8 00:00',
      //     endDate: '2021/7/28 23:59:59',
      //     startTime: '10:00',
      //     endTime: '11:00',
      //     status: 1, // 1 已完成 2 未完成
      //     allDay: true,
      //     title: '全天跨周 7-19~26超长的文字'
      //   },
      //   {
      //     id: 2,
      //     startDate: '2021/7/23 00:00',
      //     endDate: '2021/7/23 23:59',
      //     startTime: '8:00',
      //     endTime: '14:00',
      //     status: 2, // 1 已完成 2 未完成
      //     allDay: true,
      //     title: '全天完成'
      //   },
      //   {
      //     id: 3,
      //     startDate: '2021/7/23 8:00',
      //     endDate: '2021/7/23 14:00',
      //     startTime: '8:00',
      //     endTime: '14:00',
      //     status: 1, // 1 已完成 2 未完成
      //     allDay: false,
      //     title: '非全天已完成'
      //   },
      //   {
      //     id: 4,
      //     startDate: '2021/7/23 12:00',
      //     endDate: '2021/7/23 16:00',
      //     startTime: '12:00',
      //     endTime: '16:00',
      //     status: 2, // 1 已完成 2 未完成
      //     allDay: false,
      //     title: '非全天未完成'
      //   },
      //   {
      //     id: 5,
      //     startDate: '2021/7/23 12:00',
      //     endDate: '2021/7/23 16:00',
      //     startTime: '12:00',
      //     endTime: '16:00',
      //     status: 2, // 1 已完成 2 未完成
      //     allDay: false,
      //     title: '23超出多条'
      //   },
      //   {
      //     id: 6,
      //     startDate: '2021/7/11 00:00',
      //     endDate: '2021/8-4 23:59:59',
      //     startTime: '10:00',
      //     endTime: '11:00',
      //     status: 1, // 1 已完成 2 未完成
      //     allDay: true,
      //     title: '全天后+覆盖前'
      //   },
      //   {
      //     id: 7,
      //     startDate: '2021/7/30 00:00',
      //     endDate: '2021/7-30 23:59:59',
      //     startTime: '10:00',
      //     endTime: '11:00',
      //     status: 1, // 1 已完成 2 未完成
      //     allDay: false,
      //     title: '某一条1'
      //   },
      //   {
      //     id: 7,
      //     startDate: '2021/7/30 10:00',
      //     endDate: '2021/7-30 23:59:59',
      //     startTime: '8:00',
      //     endTime: '12:00',
      //     status: 2, // 1 已完成 2 未完成
      //     allDay: false,
      //     title: '某一条2'
      //   },
      //   {
      //     id: 7,
      //     startDate: '2021/7/30 10:20',
      //     endDate: '2021/7-30 23:59:59',
      //     startTime: '8:00',
      //     endTime: '12:00',
      //     status: 2, // 1 已完成 2 未完成
      //     allDay: false,
      //     title: '某一条2'
      //   },
      //   {
      //     id: 8,
      //     startDate: '2021/7/30 10:04',
      //     endDate: '2021/7-30 23:59:59',
      //     startTime: '8:00',
      //     endTime: '12:00',
      //     status: 2, // 1 已完成 2 未完成
      //     allDay: false,
      //     title: '某一条2234'
      //   },
      // ],
      // 当前聚焦的ID
      focusId: null,
      // 快速创建的待办对象
      quietCreate: null,
      // 当前查看的待办
      detailsObj: null,
      // 显示弹窗
      showModel: false,
      // 查看影藏的TODO项
      showAll: false,
      showMoreIndex: -1,
    }
  },
  computed: {
    todos() {
      const data = this.$store.getters.getActiveItem
      return data.data
    }
  },
  async created() {
    // console.log('月驶入啊啊')
    const today = new Date();
    this.todayDate = `${today.getFullYear()}/${today.getMonth() + 1}/${today.getDate()}`;
    // this.todos = await this.getTodos();
    // console.log('created' + this.todos)
    // this.setCalendar(this.timestamp);
  },
  watch: {
    async timestamp(newv) {
      // console.log(newv, '月视图当前时间戳')
      // this.todos = await this.getTodos();
      this.setCalendar(newv);
      this.$nextTick(() => {
        // 跳转顶部
        if (this.$refs.monthMain) {
          this.$refs.monthMain.scrollTop = 0;
        }
      });
    },
    todos: {
      handler(v) {
        if(v && Array.isArray(v)) {
          this.setCalendar(this.timestamp);
          this.$nextTick(() => {
            // 跳转顶部
            if (this.$refs.monthMain) {
              this.$refs.monthMain.scrollTop = 0;
            }
          });
        }
      },
      immediate: true
    }
  },
  methods: {
    /**
     * @desc: 获取待办列表，测试数据，暂未对接接口！！！！
     * @param {*}
     * @return {*}
     */
    getTodos() {
      return new Promise((resolve, reject) => {
        try {
          setTimeout(() => {
            resolve(this.formatTodos(this.todos));
          }, 0);
        } catch {
          reject([]);
        }
      });
    },
    /**
     * @desc: 设置新的日历
     * @param {*}
     * @return {*}
     */
    setCalendar(timestamp) {
      if (!timestamp || isNaN(timestamp)) return;
      const date = new Date(timestamp);
      this.dateList = [];
      let result = [];
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const startDay = `${year}/${month}/1 00:00:00`; // 开始日
      const dayCount = this.getDayCount(year, month); // 一个月有多少天
      // 开始星期几大于0表示需要前补几天
      const startWeekDay = (new Date(startDay)).getDay() === 0 ? 7 : (new Date(startDay)).getDay();
      // 每个月最多的情况会出现会出现6周的情况 即月历中需要显示42天
      const total = 42;
      // 获取上一月的数据预填充
      if (startWeekDay > 0) {
        const prevArr = this.getPrevs(year, month, startWeekDay - 1);
        result = result.concat(prevArr);
      }
      // 当前月数据
      let monthstart = 1;
      for (let i = monthstart; i <= dayCount; i++) {
        result.push({
          date: `${year}/${month}/${i}`,
          label: i, // 日历中显示的日期
          disabled: false, // 是否可以操作 上月下月均不可操作
          year: year,
          month: month,
          day: i,
          weekDay: new Date(`${year}/${month}/${i}`).getDay(), // 周几 
          timestamp: new Date(`${year}/${month}/${i} 00:00:00`).getTime(),
          isToday: this.todayDate === `${year}/${month}/${i}`,
          todoList: this.getTodoList(`${year}/${month}/${i}`)
        });
      }
      // 获取后一个月数据并进行填充
      if (total - result.length > 0) {
        const nextArr = this.getNexts(year, month, total - result.length);
        result = result.concat(nextArr);
      }
      // 最终整理一次月视图的顺序
      result = this.formatResult(result);
      // console.log(dateList, '------')
      this.dateList = result;
    },
    /**
     * @desc: 根据日期和待办列表获取当天的待办
     * @param {*} date 日期 2021/7/23
     * @return {*}
     */
    getTodoList(date) {
      if (!this.todos || !Array.isArray(this.todos) || !this.todos.length) return [];
      const result = [];
      for (let i = 0; i < this.todos.length; i++) {
        const todo = this.todos[i];
        if (todo.allDay) {
          // 全天日程
          if (this.dateIncludes(date, todo.startDate, todo.endDate, true)) {
            // 横跨多天的日程  this.getTimes(todo.endDate) - this.getTimes(todo.startDate) > 86400000
            if (new Date(todo.endDate).getDate() !== new Date(todo.startDate).getDate()) {
              const startObj = this.getSign(new Date(todo.startDate).getTime())
              const endObj = this.getSign(new Date(todo.endDate).getTime())
              result.push({
                ...todo,
                isSpan: true, // 是否为跨天日程
                isSpanStart: date === `${startObj.year}/${startObj.month}/${startObj.day}`,
                isSpanEnd: date === `${endObj.year}/${endObj.month}/${endObj.day}`,
                isMonday: new Date(date).getDay() === 1
              })
            } else {
              result.push(todo);
            }
          }
        } else {
          // 非全天日程
          if (this.dateIncludes(todo.startDate, `${date} 00:00:00`, `${date} 23:59:59`)) {
            result.push(todo);
          }
        }
      }
      if(Array.isArray(result)) {
        result.sort((a, b) => {
          return b.allDay - a.allDay
        })
      }
      return result || [];
    },
    /**
     * @desc: 将日历数据最终格式化 1.防止先开始后结束的日历日程造成 驶入差异
     * @param {*} list 日历列表
     * @return {*}
     */
    formatResult(list) {
      // console.log(list, '000000000');
      if (!list || !Array.isArray(list) || !list.length) return [];
      const result = [];
      // 顺序对象 todoId: 第一次出现的index eg. {12: 0}
      let sortObj = {};
      // 1.防止先开始后结束的跨天日程造成 视图差异
      //    记录跨天日程第一次出现的列表index
      //    将后一项与其进行比对顺序不一致则使用空白项或则该天其他非跨天日程对调顺序
      for (let i = 0; i < list.length; i++) {
        let date = list[i];
        const todos = date.todoList;
        if (todos && Array.isArray(todos) && todos.length) {
          for (let j = 0; j < todos.length; j++) {
            if (todos[j].isSpan) {
              // 跨天日程
              if (sortObj[todos[j].id] !== undefined) {
                if (j !== sortObj[todos[j].id]) {
                  // 顺序不一致
                  const index = +sortObj[todos[j].id];
                  if (todos.length > 1) {
                    // 多条日程交换顺序
                    // console.log(sortObj, j, todos[j], todos[j].id, sortObj[todos[j].id], todos,  todos[sortObj[todos[j].id]])
                    if(todos[sortObj[todos[j].id]]) {
                      const toggle = JSON.parse(JSON.stringify(todos[sortObj[todos[j].id]]));
                      todos.splice(index, 1, {
                        ...todos[j]
                      });
                      todos.splice(j, 1, {
                        ...toggle
                      });
                    }
                  } else {
                    // 仅一条日程则新增sortObj[todos[j].id 条内容
                    for (let n = 0; n < index; n++) {
                      todos.splice(0, 0, {
                        id: -1,
                        isSpace: true // 空白占位
                      });
                    }
                  }
                }
              } else {
                sortObj[todos[j].id] = j;
              }
            }
          }
          date = {
            ...date,
            todoList: todos
          }
        }
        // 补齐空白的留待快捷创建
        if (todos.length < 5) {
          let len = todos.length;
          for (let i = 0; i < 5 - len; i++) {
            todos.push({
              id: -1,
              isSpace: true, // 空白占位
              date: date.date
            });
          }
        }
        result.push(date);
      }
      // console.log(result);
      return result || [];
    },
    /**
     * @desc: 获取前一个月数据
     * @param {*} year 当前年份) number
     * @param {*} month 当前月份 number
     * @param {*} count 需要补充几天 number
     * @return {*} 上月数据数组
     */
    getPrevs(year, month, count) {
      const prevYear = month === 1 ? year - 1 : year;
      const prevMonth = month === 1 ? 12 : month - 1;
      const days = this.getDayCount(prevYear, prevMonth);
      const result = [];
      for (let i = days - count + 1 ; i <= days; i++) {
        result.push({
          date: `${prevYear}/${prevMonth}/${i}`,
          label: i, // 日历中显示的日期
          disabled: true, // 是否可以操作 上月下月均不可操作
          year: prevYear,
          month: prevMonth,
          day: i,
          weekDay: new Date(`${prevYear}/${prevMonth}/${i}`).getDay(), // 周几 
          timestamp: new Date(`${year}/${month}/${i} 00:00:00`).getTime(),
          isToday: false,
          todoList: this.getTodoList(`${prevYear}/${prevMonth}/${i}`)
        });
      }
      return result;
    },
    /**
     * @desc: 获取下一个月数据
     * @param {*} year 当前年份) number
     * @param {*} month 当前月份 number
     * @param {*} count 需要追加几天 number
     * @return {*} 下月数据数组
     */
    getNexts(year, month, count) {
      const nextYear = month === 12 ? year + 1 : year;
      const nextMonth = month === 12 ? 1 : month + 1;
      // const days = this.getDayCount(nextYear, nextMonth);
      const result = [];
      for (let i = 1 ; i <= count; i++) {
        result.push({
          date: `${nextYear}/${nextMonth}/${i}`,
          label: i, // 日历中显示的日期
          disabled: true, // 是否可以操作 上月下月均不可操作
          year: nextYear,
          month: nextMonth,
          day: i,
          weekDay: new Date(`${nextYear}/${nextMonth}/${i}`).getDay(), // 周几 
          timestamp: new Date(`${year}/${month}/${i}`).getTime(),
          isToday: false,
          todoList: this.getTodoList(`${nextYear}/${nextMonth}/${i}`)
        });
      }
      return result;
    },
    /**
     * @desc: 判断当前为大月小月，或者2月闰月 返回当月天数
     * @param {*} year 当前年 number
     * @param {*} month 当前月 number
     * @return {*}
     */
    getDayCount(year, month) {
      if (month === 2) {
        // 2月需要判断平年 闰年
        if (this.isLeapYear(year)) return 29;
        return 28;
      } 
      if (this.largeMonths.includes(month)) return 31;
      if (this.smallMonths.includes(month)) return 30;
    },
    /**
     * @desc: 判断是够为闰年 闰年能被4整除且不能被100整除，或能被400整除
     * @param {*} year 年份 number
     * @return {*}
     */
    isLeapYear(year) {
    if (isNaN(year)) return false;
    return (year % 4 === 0) && (year % 100 !== 0) || (year % 400 === 0);
    },
    /**
    * @desc: 根据时间戳返回相关日期对象
    * @param {*} times 时间戳
    * @return {*}
    */
    getSign(times) {
      if (!times || isNaN(times)) return null;
      const dateObj = new Date(times);
      const year = dateObj.getFullYear();
      const month = dateObj.getMonth() + 1;
      const day = dateObj.getDate();
      const weekDay = dateObj.getDay();
      const hours = dateObj.getHours();
      const minutes = dateObj.getMinutes();
      const seconds = dateObj.getSeconds();
      return {
        year,
        month,
        day,
        weekDay,
        hours,
        minutes,
        seconds,
        // 零点的时间
        timestampZero: new Date(`${year}/${month}/${day} 00:00:00`).getTime()
      };
    },
    /**
     * @desc: 根据日期字符串获取时间戳
     * @param {*} dateString eg. 2021/7/12 00:00
     * @return {*}
     */
    getTimes(dateString) {
      if (!dateString || !this.isSring(dateString)) return 0;
      return new Date(dateString).getTime();
    },
    /**
     * @desc:  判断某个时间是否包含在事件段内
     * @param {*} date 时间点 eg.2021/7/23 00:00
     * @param {*} startDate 开始时间点 eg.2021/7/12 00:00
     * @param {*} endDate 结束时间点 eg.2021/7/27 23:59
     * @return {*}
     */
    dateIncludes(date, startDate, endDate, type) {
      if (!date || !startDate || !endDate) return false;
      if (!this.isSring(date) || !this.isSring(startDate) || !this.isSring(endDate)) return false;
      const dateTime = this.getTimes(date);
      if(type) {
        const dateTimeA = this.getTimes(date + ' 23:59:59');
        return  dateTimeA >= this.getTimes(startDate) && dateTime <= this.getTimes(endDate)
      }
      return dateTime >= this.getTimes(startDate) && dateTime <= this.getTimes(endDate)
    },
    /**
     * @desc: 判断是否为字符串
     * @param {*} str 被判断字符串
     * @return {*}
     */
    isSring(str) {
      return typeof str === 'string';
    },
    /**
     * @desc: 格式化待办列表按开始时间排序
     * @param {*} list 需要格式化的列表
     * @return {*} result 结果列表
     */
    formatTodos(list) {
      return list.sort((prev, next) => {
        return this.getTimes(prev.startDate) - this.getTimes(next.startDate);
      });
    },
    /**
     * @desc: 鼠标移入
     * @param {*} todo 鼠标移出
     * @return {*}
     */
     onMouseover(todo) {
      // 对全天的待办
      this.focusId = todo.id;
    },
    /**
     * @desc: 鼠标移出
     * @param {*} 
     * @return {*}
     */
    onMouseout() {
      this.focusId = null;
    },
    /**
     * @desc: 全速创建
     * @param {*} item 当前点击的日期对象
     * @param {*} index 当前点击的日期Index
     * @param {*} todo 当前点击的待办
     * @param {*} todoIndex 当前点击的待办下标
     * @param {*} isFull 是否当前完整列表
     * @return {*} 
     */
    onQuietCreate(item, index, todo, todoIndex, isFull = false) {
      console.log('todo', todo)
      this.quietCreate = null;
      this.detailsObj = null;
      // 当前点击的定位修改
      if (todo.id !== -1) {
        // 点击了包含内容的日志 查看详情
        this.detailsObj = todo;
        this.popupPosition = this.onShowDetails(item, index, todo, todoIndex, isFull);
        this.showModel = true;
        return
      }
      if (!item.todoList || !Array.isArray(item.todoList) || !item.todoList.length) return;
      // 创建操作
      // 如果前五个有空白的进行创建，没有则不作操作
      for (let i = 0; i < 5; i++) {
        if (item.todoList[i].id === -1) {
          this.quietCreate = {
            date: item.date,
            spaceTodoIndex: i,
            item
          };
          break;
        }
      }
      this.detailsObj = todo;
      this.popupPosition = this.onShowDetails(item, index, this.quietCreate, this.quietCreate.spaceTodoIndex, isFull);
      this.showModel = true;
    },
    /**
     * @desc: 获取相对位置的left top
     * @param {*} item 当前点击的日期对象
     * @param {*} index 当前点击的日期Index
     * @param {*} todo 当前点击的待办
     * @param {*} todoIndex 当前点击的待办下标
     * @param {*} isFull 是否当前完整列表
     * @return {*}
     */
    onShowDetails(item, index, todo, todoIndex, isFull) {
      const monthView = this.$refs.monthView;
      const refName = `todo${index}${isFull ? '-full-' : '-'}${todoIndex}`;
      const todoItem = this.$refs[refName][0];
      if (!monthView || !todoItem) return;
      const viewHeight = window.innerHeight;
      const viewWidth = window.innerWidth;
      const { left, top, width } = todoItem.getBoundingClientRect();
      const offsetLeft = left - 10;
      const offsetTop = top - 88;
      const offsetWidth = width;
      if (viewWidth < 400) {
        return {
          left: 0,
          top: 0
        }
      }
      if (viewWidth - offsetLeft - offsetWidth < 410) {
        return {
          left: offsetLeft - (isFull ? 425 : 410),
          top: viewHeight - offsetTop < 300 ? viewHeight - 320 : offsetTop
        }
      }
      return {
        left: offsetLeft + offsetWidth + (isFull ? 25 :10),
        top: viewHeight - offsetTop < 300 ? viewHeight - 320 : offsetTop
      }
    },
    /**
     * @desc: 查看完整列表
     * @param {*} index 当前点击的日期index
     * @return {*}
     */
    onShowHide(index) {
      this.$refs[`dateItem${index}`][0].style.position = 'relative'
      this.showAll = true;
      this.showMoreIndex = index
    },
    // 小项点击
    handleItemClick(item) {
      if(item.id === -1) {
        return 
      }
      // 4.0 - 兼容处理：发送消息到引用页面
      //if(this.$checkIfVersionFour()){
        let data = {
          event:'showDetailModal',
          form_uuid:item.form_id,
          flow_id:item.flow_id,
          data_id:item.id,
          module_name:item.module_name,
          object_name:item.object_name
        }
        window.parent.postMessage(data,'*')
        return
     // }
     //this.$store.commit('setActivetaskData', item)
    },
    // 点击遮罩层
    onMask() {
      this.showModel = false;
      this.showAll = false;
      this.showMoreIndex = -1
    }
  }
}
</script>

<style lang="less" scoped>
@itemHeight: 150px;
@todoHeight: 20px;
.month-view{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .weekday{
    display: flex;
    height: 30px;
    width: 100%;
    p{
      flex: 1;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #202126;
      line-height: 30px;
      text-align: center;
      &.weekend{
        color: #8a8f99;
      }
    }
  }
  .month-main{
    flex: 1;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    border-top: 1px solid #eaebed;
    border-left: 1px solid #eaebed;
    .date-item{
      float: left;
      width: 14.285714285714286%;
      height: @itemHeight;
      box-sizing: border-box;
      border-bottom: 1px solid #eaebed;
      border-right: 1px solid #eaebed;
      display: flex;
      flex-direction: column;
      position: relative;
      &:nth-of-type(7n) {
        background: rgba(244, 245, 247, 1);
        .date-label{
          color: #8a8f99;
        }
      }
      &:nth-of-type(7n - 1) {
        background: rgba(244, 245, 247, 1);
        .date-label{
          color: #8a8f99;
        }
      }
      .date-label{
        width: 100%;
        box-sizing: border-box;
        padding: 4px;
        text-align: right;
        label{
          display: inline-block;
          height: 32px;
          min-width: 32px;
          box-sizing: border-box;
          padding: 4px 8px;
          width: auto;
          font-size: 16px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 500;
          text-align: center;
          color: #202126;
          line-height: 24px;
          border-radius: 4px;
          cursor: pointer;
          &:hover{
            // background: #cfe2f7;
          }
          &.is-today{
            background: #cfe2f7;
            color: #202126;
          }
          &.special{
            color: #8a8f99;
          }
          &.target{
            background: #cfe2f7;
            color: #202126!important;
          }
        }
      }
      .todo-list{
        flex: 1;
        // margin-top: 4px;
        // height: calc(@todoHeight * 4 + 3px);
        width: 100%;
      }
      // 超出5条
      .overflow{
        width: 100%;
        box-sizing: border-box;
        padding: 0 20px;
        margin-top: 1px;
        height: 20px;
        font-size: 12px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #8a8f99;
        line-height: 20px;
        cursor: pointer;
      }
      // 完整类表
      .all-todo{
        position: absolute;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-20%);
        margin: auto;
        width: calc(~"100% + 20px");
        height: auto;
        padding: 20px;
        min-height: calc(~"100% + 20px");
        max-height: 400px;
        background: #fff;
        border-radius: 6px;
        border: 1px solid #d9dce1;
        z-index: 100000;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width : 5px;  /*高宽分别对应横竖滚动条的尺寸*/
          height: 1px;
        }
        &::-webkit-scrollbar-thumb {
          /*滚动条里面小方块*/
          border-radius: 5px;
          box-shadow   : inset 0 0 5px rgb(125, 125, 125);
          background   : #535353;
        }
        &::-webkit-scrollbar-track {
          /*滚动条里面轨道*/
          box-shadow   : inset 0 0 5px rgb(125, 125, 125);
          border-radius: 5px;
          background   : #ededed;
        }
        box-shadow: rgba(0,0,0,.2)  0 1px 5px 0px;
        .title{
          line-height: 20px;
          font-size: 16px;
          font-weight: bold;
          color: var(--themeColor);
          text-align: left;
        }
        .list{
          .todo{
            width: 100%;
            border-radius: 4px;
            margin-left: 0;
          }
          .child-title{
            text-align: left;
          }
        }
      }
    }
  }
  .ellipsis{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .mask-box{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    background: transparent;
    z-index: 99999;
  }

  .todo{
    width: 100%;
    height: @todoHeight;
    box-sizing: border-box;
    margin-bottom: 1px;
    cursor: pointer;
    .title{
      display: block;
      margin: 0;
      padding: 0;
      border: 0;
      margin-top: -1px;
      height: 20px;
      width: 100%;
      font-size: 12px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      line-height: 20px;
      color: #202126;
    }
    // 全天
    &.full-day{
      width: calc(100% - 16px);
      box-sizing: border-box;
      height: 21px;
      margin-left: 8px;
      padding: 0 12px;
      position: relative;
      z-index: 1000;
      border-radius: 4px;
      .title{
        display: block;
      }
      &.is-span{
        width: calc(100% + 2px);
        margin-left: -1px;
        border-radius: 0;
      }
      &.is-start-full{
        width: calc(100% - 8px);
        margin-left: 8px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      &.is-end-full{
        width: calc(100% - 8px);
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      &.is-full-complate{
        background: #cfe2f7;
        .title{
          color: var(--themeColor);
        }
        &.is-foucus{
          background: var(--themeColor);
          .title{
            color: #fff;
          }
        }
      }
      &.is-full-unfinish{
        background: rgba(215,218,224,0.60);
        .title{
          color: #8a8f99;
        }
        &.is-foucus{
          background: #8a8f99;
          .title{
            color: #fff;
          }
        }
      }
    }
    // 非全天
    &.not-full-day{
      padding: 0 8px;
      height: 21px;
      display: flex;
      .point{
        display: block;
        height: 4px;
        width: 4px;
        margin-top: calc((@todoHeight - 4px) / 2);
        margin-right: 8px;
        border-radius: 2px;
        background: #2c8af5;
        &.unfinish{
          background: #8a8f99;
        }
      }
      .title{
        flex: 1;
        &.not-un-finished{
          color: #8a8f99;
        }
      }
    }
    &.target-create{
      width: calc(100% - 16px);
      box-sizing: border-box;
      margin-left: 8px;
      padding: 0 12px;
      position: relative;
      z-index: 10000;
      border-radius: 4px;
      background: var(--themeColor);
      .title{
        color: #fff;
      }
    }
  }
}
</style>