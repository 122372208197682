<template>
    <!-- 任务 -->
    <div class="right-sider-container">
        <div class="right-sider-header">
            <div class="task-header">
                <template v-for="(item, index) in breadcrumbList">
                    <span :key="index" class="active" id="breadcrumb-font" style="margin-left: 6px" @click="handleHeaderClick(index)">{{item}}</span>
                    <span :key="'st' + index" v-if="breadcrumbList.length !== 1 && index !== breadcrumbList.length - 1" class="breadcrumb-font"> /</span>
                </template>
            </div>
        </div>
       <div v-if="loadingFlag" class="loding-div">
        <div class="active"  v-loading='loadingFlag' style="text-align: center">
          <!-- <p>加载中....</p> -->
        </div>
      </div>
        <div v-else class="right-sider-main">
            <!-- 日程列表 -->
            <TaskList v-if="showType === 'taskList'" :taskData="taskData" @itemClick="handleItemClick"></TaskList>
            <!-- 日程详情 -->
            <TaskDetails v-else :taskData="taskItem" @back="handleBack"></TaskDetails>
        </div>
    </div>
</template>

<script>
import TaskList from './TaskList'
import TaskDetails from './taskDetails/index'
import { newReadCC } from '@/apis/home'
import { toTurn } from '@/components/todoList/main/modulAbout'
export default {
    components: { TaskList, TaskDetails },
    data() {
        return {
            breadcrumbList: ['日程列表'],
            showType: 'taskList',
            taskItem: {}
        }
    },
    mounted(){
            this.breadcrumbList =  ['日程列表']
            this.taskItem = {}
            this.showType ='taskList'
    },
    computed: {
        taskData() {
            return this.$store.getters.getActiveItem
        },
        loadingFlag() {
            return this.$store.getters.getSearchLoding
        },
    },
    methods: {
        handleHeaderClick(index) {
            if(index === 0) {
                this.handleBack()
            }
        },
        // 进入详情
        handleItemClick(item) {
            if (item.id) {
                if(item.module_name == 'assignment') {
                    // 如果是任务
                    this.showType = 'taskDetails'
                    this.taskItem = item
                    if (this.breadcrumbList.length === 1) {
                        this.breadcrumbList.push('详情')
                    }
                } else {
                // 如果该待办不属于当前架构
                const archiId = localStorage.getItem('archi').split('/')[2]
                    if (archiId != item.archi_id) {
                        this.$Message.info(`"${item.title}", 该待办不属于当前组织架构，请切换组织架构后查看！`)
                        // 清除活动任务内容
                        this.taskItem = {}    
                        return
                    }
                    toTurn(this, item.module_name, item)
                }
            }
            
            // 如果是抄送读抄送
            if(item.msg_type === 3) {
                this.newReadCC(item.msg_id)
            }
        },
        // 标记抄送已读
        newReadCC(id) {
            console.log('读抄送')
            newReadCC({id}).then(res => {
                if(res.data.code === 200) {
                this.searchData()
                }
            })
        },
        // 退回列表
        handleBack() {
            console.log('back')
            this.showType = 'taskList'
            this.taskItem = {}
            if (this.breadcrumbList.length === 2) {
                this.breadcrumbList.pop()
            }
        }
    }
}
</script>

<style lang="less" scoped>
    .right-sider-container{
        padding: 0px;
        width: 100%;
        height: 100%;
        .right-sider-header{
            padding: 2px 6px;
            .task-header{
                padding: 5px 0;
                .header-font{
                    color: rgba(0,129,153,1);
                    font-size: 14px;
                }
                #breadcrumb-font{
                    font-size: 14px;
                    &:last-child{
                        color: rgba(138,143,153,1) !important;
                    }
                }
                .float-right{
                    float: right;
                }
            }
        }
        .right-sider-main{
            height: calc(~"100% - 26px")
        }
        // loading效果
        .loding-div{
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          @keyframes reateIcon {
            0% {transform: rotate(0deg);}
            100% {transform: rotate(360deg)}
          }
          .reate-icon{
            animation-name: reateIcon;
            animation-duration: 1s;
            animation-iteration-count: infinite;
          }
        }
    }

</style>