<!--
 * @Description: 待办列表及详情区域
 * @Author: luocheng
 * @Date: 2021-07-15 15:42:15
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-03-31 16:10:07
-->
<template>
  <div class="details">
    <!-- 任务列表 -->
    <TaskList></TaskList>
  </div>
</template>

<script>
import TaskList from './taskList/index'
export default {
  name: 'Details',
  components: { TaskList }
}
</script>

<style lang="less" scoped>
@width: 336px;
.details{
  padding: 0 12px;
  height: 100%;
  width: @width;
  border-right: 1px solid #eaebed;
  background: #f2f3f5;
  box-sizing: border-box;
}
</style>