<template>
    <!-- 列表视图 -->
    <div class="list-view">
        <!-- 新增任务 -->
        <!-- <div class="mini-task-editor" style="color:#0076ff" v-if="dataList && dataList.type !== '-1'">
            <div v-if="miniEditor === false">
                <span class="active" style="cursor:pointer;" @click="handleActiveClick">
                    <i class="iconfont iconxinzeng2"></i>
                    <span>新增任务</span>
                </span>
            </div>
            <div v-else class="mimi-editor-main">
                <div class="editor-mian" id="editor-mian">

                    <el-input ref="input_task" @blur="inputTaskBlur" @focus="inputTaskFocus" v-model="taskData.name" placeholder="新增任务，回车快速创建" @keypress.native.enter="addTask"/>
                     <div style="padding: 5px;" :style="{visibility:  detailsShow ? 'visible':  'hidden'}">
                    <div style="padding: 5px;" v-show="detailsShow"> -->
                        <!-- 点击打开大编辑窗  -->
                       <!-- <i class="el-icon-alarm-clock active"> </i>
                        <span class="active" style="cursor:pointer;" @click="handleFullScenc">明天24:00截止</span>
                        &nbsp;&nbsp;&nbsp;
                        <i class="el-icon-s-custom active"> </i>
                        <span class="active" style="cursor:pointer;" @click="handleFullScenc">{{ currenrUser }}</span>
                    </div>
                </div>
                <div>
                    <div style="padding: 5px 15px">
                        <i class="iconfont iconquanping1 active" style="font-size: 30px; cursor:pointer;" @click="handleFullScenc"></i>
                    </div>
                </div>
                <div>
                    <div style="padding: 5px 15px">
                        <el-button class="add-btn" :loading="loading" @click="handleAdd">新建</el-button>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- 列表主体 -->
        <div v-if="dataList && dataList.type !== '-1'" class="list-view-main" :style="miniEditor ? `height: calc(100% - 85px);` : 'height: calc(100%);'">
            <ul v-if="dataList.data && dataList.data.length" style="height: 100%;overflow: auto;" class="scrollCont" :loading="false" v-infinite-scroll="load">
                <li class="list-view-item" v-for="(item,index) in dataList.data" :key='index'>
                    <div class="left-item-status" :style="getStausColor(item.status)"></div>
                    <div class="list-item-main" @click="handleItemClick(item)">
                        <div class="item-main-top">
                            <!-- 优先级 -->
                            <span v-if="item.priority && item.priority !== 0" class="task-priority">{{'!'.repeat(item.priority)}} &nbsp;</span>
                            <!-- 标题 -->
                            <span class="header-title">
                                {{ item.title }}
                            </span>
                            &nbsp;
                            <!-- 状态 -->
                            <span v-if="item.msg_type !== 3" class="header-status" :style="getStatusLabeColor(item.status)">
                                {{ getStutustext(item.status) }}
                            </span>
                            <span v-else class="header-status" :style="getStatusLabeColor(item.status)">
                                抄送
                            </span>

                        </div>
                        <div class="item-main-bottom">
                            <i class="iconfont item-bottom-font" :class="getModulIcon(item.module_name)" style="font-size: 14px;"></i>
                            &nbsp;&nbsp;
                            <p class="project-name">项目：{{ item.archi_name }}&nbsp;&nbsp;|&nbsp;&nbsp;</p>
                              <span class="modul-name item-bottom-font">
                                  {{ getModulName(item.module_name,item.object_name).typeName }}
                              </span>
                              <span class="item-bottom-font">&nbsp;&nbsp;•&nbsp;&nbsp;</span>
                              <span class="item-bottom-font">{{nameFormat(item.from_user_name)}} ＞
                              <span class="active">{{nameFormat(item.user_name)}}</span></span>
                              <!-- <span class="item-bottom-font">&nbsp;&nbsp;•&nbsp;&nbsp;</span>
                              <i class="iconfont iconzirenwushuliang item-bottom-font" style="font-size: 14px;"></i>
                              <span class="item-bottom-font">1/3</span> -->
                            <!-- </section> -->
                        </div>
                    </div>
                    <div class="right-item-footer">
                        <div class="footr-main">
                            <div v-if="item.msg_type !== 3" class="footer-icon">
                              <div>
                                <i v-if="item.is_top" class="iconfont iconzhiding active" @click.stop="handleRomoveTop(item)"></i>
                                <i v-else class="iconfont iconzhiding hoverVisable" @click.stop="handleSetTop(item)"></i>
                              </div>

                            </div>
                            <div v-else class="footer-icon">
                                <i style="visibility: hidden;" class="iconfont iconzhiding"></i>
                            </div>
                            <div class="footer-text" id="footer-text">
                                <span class="footer-span active" :class="deadLine(item).class" v-text="deadLine(item, item.endDate).time"></span>
                            </div>
                        </div>

                    </div>
                </li>
            </ul>
            <!-- <emptyCont v-else text="暂无内容!"/> -->
        </div>
        <div v-if="dataList && dataList.type == '-1'" class="list-view-main" style="height: calc(100% - 35px);">
            <ul v-if="finishList && finishList.length" height="100%" style="height: 100%" class="scrollCont" :loading="false" v-infinite-scroll="load" >
                    <li v-for="(el, index) in finishList" :key="index">
                        <p class="time-filed">{{el.timeFiled}}</p>
                        <!-- 单项 -->
                            <div class="list-view-item" v-for="(item, index) in el.data" :key="'taskitem' + index">
                                <div class="left-item-status" :style="getStausColor(item.status)"></div>
                                <div class="list-item-main" @click="handleItemClick(item)">
                                    <div class="item-main-top">
                                        <!-- 优先级 -->
                                        <span v-if="item.priority && item.priority !== 0" class="task-priority">{{'!'.repeat(item.priority)}} &nbsp;</span>
                                        <!-- 标题 -->
                                        <span class="header-title">
                                            {{ item.title }}
                                        </span>
                                        &nbsp;
                                    </div>
                                    <div class="item-main-bottom">
                                        <i class="iconfont item-bottom-font" :class="getModulIcon(item.module_name)" style="font-size: 14px;"></i>
                                        &nbsp;&nbsp;
                                        <p class="project-name">项目：{{ item.archi_name }}&nbsp;&nbsp;|&nbsp;&nbsp;</p>
                                        <span class="modul-name item-bottom-font">
                                            {{ getModulName(item.module_name,item.object_name).typeName }}
                                        </span>
                                        <span class="item-bottom-font">&nbsp;&nbsp;•&nbsp;&nbsp;</span>
                                        <span class="item-bottom-font">{{nameFormat(item.from_user_name)}} ＞
                                        <span class="active">{{nameFormat(item.user_name)}}</span></span>
                                        <!-- <span class="item-bottom-font">&nbsp;&nbsp;•&nbsp;&nbsp;</span>
                                        <i class="iconfont iconzirenwushuliang item-bottom-font" style="font-size: 14px;"></i>
                                        <span class="item-bottom-font">1/3</span> -->
                                    </div>
                                </div>
                                <div class="right-item-footer">
                                    <div class="footr-main">
                                        <div class="footer-icon">
                                        </div>
                                        <div class="footer-text" id="footer-text">
                                            <span class="footer-span active" :class="deadLine(item).class" v-text="deadLine(item, item.endDate).time"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </li>
            </ul>
            <emptyCont v-else text="暂无内容!"/>
        </div>
        <div v-if="dataList && dataList.type == '-1'" style="text-align: right; padding: 10px 0" class="list-view-footer">
            <el-Pagination :current-page="page.page" :page-size='pageSize' @current-change="handlePageChange" :total="dataList.total" simple />
        </div>
    </div>
</template>

<script>
import { Pagination } from "element-ui";
import { format } from "date-fns";
import { getModulName } from "../modulAbout.js";
import { OverheadApi, cancelOverheadApi } from "@/apis/home";
import infiniteScroll from "vue-infinite-scroll";

export default {
  components: {
    //'el-button':Button,
    "el-Pagination": Pagination,
    //'el-input':Input,
  },
	directives: { infiniteScroll },
  mounted () {
    this.currentUserName = JSON.parse(
      localStorage.getItem("userInfo")
    )?.name?.replaceAll('"', "");
  },
  data () {
    return {
      // 迷你编辑器
      pageSize: 15,
      total: 21,
      miniEditor: false,
      // currenrUser: localStorage.getItem('userInfo').name.replaceAll('"', ''),
      // 新增数据
      // taskData: {
      //     archi_id: localStorage.getItem('archi').split('/')[2],
      //     name: '',
      //     task_type: 1,
      //     deadline: format(addDays(new Date(), 1), 'YYYY-MM-dd') + ' 23:59:59',
      //     priority: 1,
      //     trigger: 1,
      //     start_time: format(new Date(), 'YYYY-MM-dd HH:mm:ss'),
      //     receivers: [{
      //         user_id: localStorage.getItem('userId').replaceAll('"', ''),
      //         user_name:localStorage.getItem('userInfo').name.replaceAll('"', ''),
      //         role_id: JSON.parse(localStorage.getItem('thisRole')).id,
      //         role_name: JSON.parse(localStorage.getItem('thisRole')).name,
      //         type: 1
      //     }],
      //     approver: [{
      //         user_id: localStorage.getItem('userId').replaceAll('"', ''),
      //         role_id: JSON.parse(localStorage.getItem('thisRole')).id,
      //     }],
      //     module_name: 'assignment',
      //     status: 1,
      //     is_process: 1,
      //     permitted: 1,
      // },
      currentUserName: "",
      // 已完成
      finishList: [],
      loading: false,
      detailsShow: false,
    };
  },
  computed: {
    // 分页
    page () {
      return this.$store.getters.getFinishSearchParams;
    },
    dataList () {
      const data = this.$store.getters.getActiveItem || {};
      if (data.type === "-1") {
        // 处理未完成分页
        this.dealFinishList(data);
      }
      return data;
    },
  },
  methods: {
    load(){

    },
    handleActiveClick () {
      this.miniEditor = true;
      setTimeout(() => {
        this.$refs.input_task.focus();
      }, 10);
    },
    // inputTaskBlur() {
    //     if(this.taskData.name) {
    //         setTimeout(() => {
    //             this.detailsShow = false
    //         }, 200)
    //     } else {
    //         setTimeout(() => {
    //             this.miniEditor = false
    //         }, 200)
    //     }
    // },
    inputTaskFocus () {
      this.detailsShow = true;
    },
    //分页变化
    handlePageChange (v) {
      this.$store.commit("setFinishSearchParams", { size: 15, page: v });
    },
    // 处理未完成分页
    dealFinishList (data) {
      const arr = [];
      // 分割标志
      const splitFlag = "created_at";
      data.data.map((el) => {
        // 分割时间段
        const s = arr.find((item) => {
          return (
            item.timeFiled ===
            el[splitFlag].substring(0, 10).replaceAll("/", "-")
          );
        });
        if (s) {
          s.data.push(el);
        } else {
          arr.push({
            timeFiled: el[splitFlag].substring(0, 10).replaceAll("/", "-"),
            data: [el],
          });
        }
      });
      this.finishList = arr;
    },
    // 新增任务
    // addTask() {
    //     // console.log('回车新建事件', this.taskData)
    //     if(this.loading) {
    //         return
    //     }
    //     if(!(this.taskData.name)) {
    //         this.$Message.info('请填写任务标题')
    //         return
    //     }
    //     this.loading = true
    //     addTaskApi(this.taskData).then(res => {
    //         if (res.data.code === 200) {
    //             this.taskData.name = ''
    //             this.miniEditor = false
    //             this.$emit('refresh')
    //         }
    //     }).catch(err => {console.log(err)}).finally(() => {

    //         this.loading = false
    //     })
    // },
    // 获取前面状态的颜色
    getStausColor (status) {
      if ([2, 5].includes(status)) {
        return "background: rgba(138,143,153,1)";
      }
      switch (status) {
        case 6 :{
          return "background:#E32C35"
        }
        case 7 :{
          // 作废
          return "background: #9b6ceb;"
        }
        case -1: {
          //退回
          return "background: rgba(240,113,86,1)";
        }
        case 0: {
          //发起
          return "background: rgba(46,191,118,1)";
        }
        default: {
          //审核中
          return "background: rgba(68,149,242,1)";
        }
      }
    },
    // 获取状态的背景色和颜色
    getStatusLabeColor (status) {
      if ([2, 5].includes(status)) {
        return "background: rgba(242,243,245,1); color: rgba(82,89,102,1);";
      }
      switch (status) {
        case 6 :{
          return "background:#FFE5E6;color:#E32C35"
        }
        case 7 :{
          // 作废
          return "background: #efe6ff;color: #9b6ceb;"
        }
        case -1: {
          //退回
          return "background: #f9ece9; color: rgba(204,68,41,1);";
        }
        case 0: {
          //发起
          return "color: #2a953f;background: rgba(233,244,235,1);";
        }
        default: {
          //审核中
          return "background: rgba(233,242,251,1); color: rgba(17,111,217,1);";
        }
      }
    },
    // 获取状态文本
    getStutustext (status) {
      if ([2, 5].includes(status)) {
        return "已完成";
      }
      switch (status) {
        case 6 :{
          return "拒绝"
        }
        case 7 :{
          return "作废"
        }
        case -1: {
          //退回
          return "退回";
        }
        case 0: {
          //发起
          return "发起";
        }
        default: {
          return "审核中";
        }
      }
    },
    // 格合化人名
    nameFormat (name) {
      if (name) {
        return name === this.currentUserName ? "我" : name;
      } else {
        return "-";
      }
    },
    // 获取模块图标
    getModulIcon (modulName, objName) {
      return getModulName(modulName, objName).icon;
    },
    // 时间格式化
    deadLine (item) {
      const deadLine = item.endDate;
      let date1_s = new Date(deadLine.replace(/-/g, "/"));
      const nowTime = new Date();
      let dateDiff = nowTime.getTime() - date1_s.getTime();
      let date = Math.floor(dateDiff / (24 * 3600 * 1000)); //计算出相差天数

      if ([2, 5].includes(item.status)) {
        return {
          time: this.finishedTimeFormat(deadLine),
          class: "gray-time",
        };
      }
      if (nowTime.getTime() > date1_s.getTime() && date) {
        return {
          time: "逾期" + date + "天",
          class: "red-time",
          tag: true,
        };
      } else {
        return {
          time: this.timeFormat(item.endDate),
        };
      }
    },
    timeFormat (time) {
      if (!this.nowDateObj) {
        this.nowDateObj = new Date();
      }
      const targetDate = new Date(time);
      const ny = this.nowDateObj.getFullYear();
      const ty = targetDate.getFullYear();
      const nm = this.nowDateObj.getMonth();
      const tm = targetDate.getMonth();
      const nd = this.nowDateObj.getDate();
      const td = targetDate.getDate();
      if (ny === ty) {
        if (nm === tm) {
          if (nd === td) {
            return `今天${format(targetDate, "HH:mm")}截止`;
          } else if (td - nd === 1) {
            return `明天${format(targetDate, "HH:mm")}截止`;
          } else {
            return `${format(targetDate, "MM-dd HH:mm")}截止`;
          }
        } else {
          return `${format(targetDate, "MM-dd HH:mm")}截止`;
        }
      } else {
        return `${format(targetDate, "yyyy-MM-dd HH:mm")}截止`;
      }
    },
    finishedTimeFormat (time) {
      if (!this.nowDateObj) {
        this.nowDateObj = new Date();
      }
      const targetDate = new Date(time);
      const ny = this.nowDateObj.getFullYear();
      const ty = targetDate.getFullYear();
      const nm = this.nowDateObj.getMonth();
      const tm = targetDate.getMonth();
      const nd = this.nowDateObj.getDate();
      const td = targetDate.getDate();
      if (ny === ty) {
        if (nm === tm) {
          if (nd === td) {
            return `今天${format(targetDate, "HH:mm")}`;
          } else if (nd - td === 1) {
            return `昨天${format(targetDate, "HH:mm")}`;
          } else {
            return `${format(targetDate, "MM-dd HH:mm")}`;
          }
        } else {
          return `${format(targetDate, "MM-dd HH:mm")}`;
        }
      } else {
        return `${format(targetDate, "yyyy-MM-dd HH:mm")}`;
      }
    },
    //全屏事件
    // handleFullScenc() {
    //     // console.log('接下来打开大编辑窗')
    //     this.$store.commit('setDrewerData', JSON.parse(JSON.stringify({ type: 'add', data : this.taskData})))
    //     this.$store.commit('setOpenDrawer', true)

    // },
    handleAdd () {
      // console.log('新建事件', this.taskData)
      this.addTask();
    },
    // 小项点击事件
    handleItemClick (item) {
      // 4.0 - 兼容处理：发送消息到引用页面
      // if(this.$checkIfVersionFour()){
      console.log("点击了")
      let data = {
        event: "showDetailModal",
        form_uuid: item.form_id,
        flow_id: item.flow_id,
        data_id: item.id,
        module_name: item.module_name,
        object_name:item.object_name,
        archi_type : item.archi_type
      };

      if(item.task_engine_id){
        let {form_type,func_form,task_recover_id,task_engine_id,task_log_id,object_uuid} = item
        const taskConfig = {form_type,func_form,task_recover_id,task_engine_id,task_log_id,object_uuid}
        data.taskConfig = taskConfig
      }

      window.parent.postMessage(data, "*");
      return;
      //  }
      // console.log('列表小项点击', item)
      // this.$store.commit('setActivetaskData', item)
    },
    // 置顶事件
    handleSetTop (item) {
      // console.log('置顶', item)
      const data = {
        msg_type: item.msg_type,
        module_name: item.module_name,
        id: item.id,
      };
      OverheadApi(data).then((res) => {
        if (res.data.code === 200) {
          this.$store.commit("setRefesh");
        }
      });
    },
    // 取消置顶
    handleRomoveTop (item) {
      // console.log('取消置顶', item)
      const data = {
        msg_type: item.msg_type,
        module_name: item.module_name,
        id: item.id,
      };
      cancelOverheadApi(data).then((res) => {
        if (res.data.code === 200) {
          this.$store.commit("setRefesh");
        }
      });
    },
    // 获取模块名
    getModulName (typeName, objName) {
      return getModulName(typeName, objName);
    },
  },
};
</script>

<style lang="less" scoped>
.list-view {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .mini-task-editor {
    padding: 10px 0px;
    .mimi-editor-main {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #f5f5f5;
      .editor-mian {
        flex-grow: 1;
      }
    }
  }
  .list-view-main {
    flex: 1;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    .time-filed {
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #202126;
      line-height: 22px;
      padding: 10px 0;
    }
    .list-view-item {
      cursor: pointer;
      display: flex;
      border-bottom: 1px solid #f5f5f5;
      &:hover {
        background: rgba(0, 129, 153, 0.1);
        .hoverVisable {
          visibility: visible;
        }
      }
      .left-item-status {
        min-width: 4px;
        border-radius: 4px 0 0 4px;
        background: rgba(68, 149, 242, 1);
      }
      .list-item-main {
        padding: 12px 20px;
        flex-grow: 1;
        .item-main-top {
          .task-priority {
            color: rgba(221, 44, 47, 1);
            font-size: 14px;
          }
          .header-title {
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: left;
            color: #202126;
            line-height: 22px;
          }
          .header-status {
            padding: 2px 8px;
            font-size: 12px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: left;
            line-height: 20px;
            border-radius: 10px;
          }
          .task-finish {
            color: #2a953f;
            background: rgba(233, 244, 235, 1);
          }
        }
        .item-main-bottom {
          display: flex;
          align-items: center;
          .project-name{
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              // flex-grow: 1;
              // width: 10px;
              height: 20px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7c808a;
            line-height: 20px;
          }
          .item-bottom-font {
            font-size: 12px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: left;
            color: #7c808a;
            line-height: 20px;
          }
        }
      }
    }
  }
  .right-item-footer {
    display: flex;
    justify-items: center;
    min-width: 120px;
    .footr-main {
      margin: 0;
      width: 100%;
      padding: 5px 20px;
      .footer-icon {
        text-align: right;
      }
      .hoverVisable {
        visibility: hidden;
      }
      .footer-text {
        text-align: right;
        .footer-span {
          font-size: 12px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: right;
          line-height: 20px;
        }
        .red-time {
          color: rgba(184, 37, 41, 1) !important;
        }
      }
      #footer-text {
        .red-time {
          color: rgba(184, 37, 41, 1) !important;
        }
        .gray-time {
          color: rgba(138, 143, 153, 1) !important;
        }
      }
    }
  }
}
</style>

<style lang="less">
  .mimi-editor-main {
    #editor-mian {
      .ivu-input {
        border: none;
        &:focus {
          box-shadow: none;
          // border-bottom: 1px solid #f5f5f5;
        }
        &:hover {
          box-shadow: none;
          // border-color: #f5f5f5 !important;
          // border-bottom: 1px solid #f5f5f5 !important;
        }
        &:active {
          box-shadow: none;
          // border-bottom: 1px solid #f5f5f5;
        }
        &:visited {
          box-shadow: none;
          // border-bottom: 1px solid #f5f5f5;
        }
        &:focus-visible {
          outline: none;
        }
      }
    }
    .add-btn {
      border-radius: 6px !important;
      padding: 8px 8px !important;
      height: auto !important;
      background: rgba(82, 89, 102, 1) !important;
      color: #fff !important ;
      span {
        writing-mode: vertical-lr !important;
      }
    }
  }
  .list-view {
    .vxe-list--virtual-wrapper {
      &::-webkit-scrollbar {
        width: 6px;
        height: 8px;
        margin: 0 3px;
      }
      &::-webkit-scrollbar-track {
        background-color: #ffffff;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(189, 192, 199, 1);
        border-radius: 4px;
        /*border: 1px solid #F1F1F1;*/
        /*box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: rgba(189, 192, 199, 1);
      }
      &::-webkit-scrollbar-thumb:active {
        background-color: rgba(189, 192, 199, 1);
      }
    }
  }
</style>
